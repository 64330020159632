import Constants from './Constants';
import TextService from './TextService';

export default class Validator {

  static isEmailValid = (email) => {
    return /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email);
  }

  static isHashValid = (hash, letter) => {
    return /^[a-z]{10}$/.test(hash) && Object.values(Constants.hash).includes(hash[0]) && (!letter || hash[0] === letter);
  }

  static getValidationMessages = (stir) => {
    let messages = { 1: [], 2: [], 3: [], 4: [] };
    const theme = stir.getTheme();
    const requireEmail = !stir.participantNamesOnly;
    const participantCount = stir.getParticipantCount();
    const participantWithEmailCount = stir.participants.filter(p => this.isEmailValid((p.email || "").trim())).length;
    const adminCount = stir.getAdminCount();
    const observerCount = stir.getObserverCount();

    // Step 1

    if (participantCount <= 0) {
      messages[1].push(`Enter valid names ${requireEmail ? "and emails " : ""}in the fields below.`);
    }
    if (requireEmail && participantCount !== participantWithEmailCount) {
      messages[1].push("Enter a name and valid email for each participant.");
    }
    if (requireEmail && adminCount <= 0) {
      messages[1].push("At least one participant must be an administrator.");
    }
    if (observerCount === participantCount) {
      messages[1].push("At least one participant must not be an observer.");
    }
    if (!requireEmail && stir.participants.filter(p => p.name).map(p => p.name.toLowerCase()).length > 0 && [...new Set(stir.participants.filter(p => p.name).map(p => p.name.toLowerCase()))].length !== stir.participants.filter(p => p.name).map(p => p.name.toLowerCase()).length) {
      messages[1].push("Duplicate participant names are not allowed.");
    }
    if (stir.participants.filter(p => p.email).map(p => p.email.toLowerCase()).length > 0 && [...new Set(stir.participants.filter(p => p.email).map(p => p.email.toLowerCase()))].length !== stir.participants.filter(p => p.email).map(p => p.email.toLowerCase()).length) {
      messages[1].push("Duplicate participant emails are not allowed.");
    }
    if (Validator.isHashValid(theme.hash, Constants.hash.theme) && participantCount < stir.getMinParticipantCount()) {
      messages[1].push(`${theme.name} requires at least ${theme.minParticipants} participants.`);
    }
    if (Validator.isHashValid(theme.hash, Constants.hash.theme) && participantCount > stir.getMaxParticipantCount()) {
      messages[1].push(`${theme.name} cannot have more than ${theme.maxParticipants} participants.`);
    }

    // Step 2
    if ((stir.isEditingTheme || [Constants.themeType.Generated, Constants.themeType.MirrorParticipants].includes(stir.selectedThemeTypeId)) && !stir.customThemeName.trim().length) {
      messages[2].push("Enter a name for your custom teams.");
    }
    if ([Constants.themeType.Game, Constants.themeType.ExistingCustom].includes(stir.selectedThemeTypeId) && !stir.selectedThemeHash?.length && !stir.isEditingTheme) {
      messages[2].push("Select a game from the dropdown.");
    }
    if (stir.isEditingTheme && !stir.customTeamText.trim().length) {
      messages[2].push("Enter your custom team names.");
    }
    if (stir.selectedThemeTypeId === Constants.themeType.MirrorParticipants && !participantCount) {
      messages[2].push("Select participants to be used as your teams.");
    }
    if ([...new Set(stir.customTeamText.trim().toLowerCase().split("\n"))].length !== stir.customTeamText.trim().toLowerCase().split("\n").length) {
      messages[2].push("Duplicate team names are not allowed.");
    }

    // Step 3
    let hasAssign = false;
    for (let i = 0; i < stir.methodSteps.length; i++) {
      const step = stir.methodSteps[i];
      const isAssign = step.isAssign();
      const isMirror = stir.selectedThemeTypeId === Constants.themeType.MirrorParticipants;
      const method = stir.getMethod(step.methodId);
      const algorithm = stir.getAlgorithm(step.algorithmId);
      const previousStepsWithTimeLimitSupported = stir.methodSteps.filter(x => x.sequence <= i && stir.getMethod(x.methodId).isTimeLimitSupported);
      hasAssign = hasAssign || isAssign;

      if (method.isTimeLimitSupported && step.dateShouldResolve && step.dateShouldResolve <= new Date()) {
        messages[3].push("Deadlines must be in the future.");
      }
      if (step.dateShouldResolve && method.isTimeLimitSupported && previousStepsWithTimeLimitSupported.some(x => !x.dateShouldResolve && stir.getMethod(x.methodId).isTimeLimitSupported)) {
        messages[3].push("Methods cannot have deadlines if any preceding method has an unlimited deadline.");
      }
      if (step.dateShouldResolve && method.isTimeLimitSupported && previousStepsWithTimeLimitSupported.some(x => x.dateShouldResolve >= step.dateShouldResolve)) {
        messages[3].push("Each deadline must be later than the previous deadline.");
      }
      if (step.input && isAssign && !isMirror && (step.input.teamCount < Constants.minAssignTeamCount) && step.canPredict() && !step.isSelfAssign()) {
        messages[3].push(`Assign methods need at least ${TextService.pluralize(Constants.minAssignTeamCount, "team")}.`);
      }
      if (step.input && !isAssign && !stir.selectedThemeTypeId === Constants.themeType.MirrorParticipants && (step.input.teamCount || step.input.matchCount) < Constants.minElectOrExcludeTeamCount) {
        messages[3].push(`Elect and exclude methods need at least ${TextService.pluralize(Constants.minElectOrExcludeTeamCount, "team")}.`);
      }
      if (step.input && isAssign && isMirror && step.input.participantCount === 1) {
        messages[3].push("Cannot mirror participants with only one participant.");
      }
      if (!step.methodId || step.methodId <= 0 || !method || method.methodId <= 0) {
        messages[3].push("Select a method from the list of available options.");
      }
      if (!step.algorithmId || step.algorithmId <= 0) {
        messages[3].push("Select an algorithm from the list of available options.");
      }
      if (isAssign && isMirror && participantCount % 2 !== 0) {
        messages[3].push("Mirroring participants only supports an even number of participants.");
      }
      if (method && method.isEmailRequired && (participantCount !== participantWithEmailCount || !requireEmail)) {
        messages[3].push(`The ${method.name} method requires that all participants have valid emails.`);
      }
      if (step.algorithmId === Constants.algorithmType.Roommate && step.input.teamCount % 2 !== 0) {
        messages[3].push(`The ${algorithm.name} algorithm only supports an even number of teams.`);
      }
      if (step.algorithmId === Constants.algorithmType.Roommate && !isMirror) {
        messages[3].push(`The ${algorithm.name} algorithm only supports teams that are mirrored from participants.`);
      }
    }

    // Step 4

    if (stir.customMessage && stir.customMessage.length > 1000) {
      messages[4].push("The stir message to participants cannot be longer than 1,000 characters.");
    }
    const shouldValidateEvent = (stir.eventName || stir.eventDate || stir.eventAddress || stir.eventMessage) && !stir.eventHash;
    if (shouldValidateEvent) {
      if (!stir.eventName) {
        messages[4].push("Enter a name for your event.");
      }
      if (stir.eventName && stir.eventName.length < 3) {
        messages[4].push("Event names must be at least 3 characters long.");
      }
      var today = new Date();
      today.setHours(0, 0, 0, 0);
      if (stir.eventDate < today) {
        messages[4].push("Enter a time that is not in the past.");
      }
      if (stir.eventAddress && stir.eventAddress.length > 1000) {
        messages[4].push("Address cannot be longer than 1,000 characters.");
      }
      if (stir.eventMessage && stir.eventMessage.length > 1000) {
        messages[4].push("Message cannot be longer than 1,000 characters.");
      }
    }

    return messages;
  };

}
