import React, { useEffect } from 'react';
import { Form, FormLabel } from 'react-bootstrap';
import { PredictionDisplay } from './PredictionDisplay';
import { Icon } from '../shared/Icon';
import TextService from '../../services/TextService';
import DateService from '../../services/DateService';
import Constants from '../../services/Constants';
import DatePicker from 'react-datepicker';

export const action = {
  expand: 1,
  collapse: 2,
  delete: 3
};

export const getIo = (teamCount, participantCount, matchCount, step) => {
  return {
    teamCount,
    participantCount,
    matchCount,
    getAssignType: () => step?.assignType,
    isAmbiguousCount: () => !step?.canPredict() || ((step.sequence > 1) && step.input.isAmbiguousCount()),
  };
};

export const createMethodStep = (sequence, urlParams) => {
  let methodId = 0;
  let algorithmId = 0;
  const method = urlParams ? urlParams.firstStepMethod : ""; //urlParam is used by homepage links
  switch (method) {
    case "random":
      methodId = Constants.methodType.Random;
      algorithmId = Constants.algorithmType.Random;
      break;
    case "group":
      methodId = Constants.methodType.Group;
      algorithmId = Constants.algorithmType.Hungarian;
      break;
    case "rank":
      methodId = Constants.methodType.Rank;
      algorithmId = Constants.algorithmType.Hungarian;
      break;
    case "draft":
      methodId = Constants.methodType.Draft;
      algorithmId = Constants.algorithmType.Draft;
      break;
    case "admin":
      methodId = Constants.methodType.Admin;
      algorithmId = Constants.algorithmType.Predetermined;
      break;
    case "selfAssign":
      methodId = Constants.methodType.SelfAssign;
      algorithmId = Constants.algorithmType.Predetermined;
      break;
  }

  return {
    sequence: sequence,
    methodId: methodId,
    algorithmId: algorithmId,
    dateShouldResolve: null,
    resolutionTypeId: Constants.resolutionType.Assign,
    resolutionCount: 1,
    shouldEmailParticipants: !method === "random", //this field is never actually used anyway
    isAssign() { return this.resolutionTypeId === Constants.resolutionType.Assign },
    isElect() { return this.resolutionTypeId === Constants.resolutionType.Elect },
    isExclude() { return this.resolutionTypeId === Constants.resolutionType.Exclude },
    isAdminAssign() { return this.isAssign() && this.methodId === Constants.methodType.Admin },
    isSelfAssign() { return this.isAssign() && this.methodId === Constants.methodType.SelfAssign },
    isArbitraryAssign() { return this.isAdminAssign() || this.isSelfAssign() },
    canPredict() { return !(this.isAdminAssign()  || (this.isSelfAssign() && this.resolutionCount === 0)); },
    input: getIo(0, 0, 0, this),
    output: getIo(0, 0, 0, this),
  };
}

const getResolutionOptions = (step, isMirror) => {
  const input = getIo(step.input.teamCount, step.input.participantCount, step.input.matchCount, step);
  if (step.isAssign()) {
    let options = [];
    const maxTeamsPerParticipant = input.participantCount === 0 ? 0 : Math.floor(input.teamCount / input.participantCount);
    const maxParticipantsPerTeam = input.teamCount === 0 ? 0 : Math.floor(input.participantCount / input.teamCount);
    const maxAssignsPerParticipant = !step.isSelfAssign() ? 0 : Math.floor(input.matchCount / input.participantCount);
    const hasLeftoverTeams = input.teamCount > input.participantCount && input.teamCount / input.participantCount != maxTeamsPerParticipant;
    const hasLeftoverParticipants = input.participantCount > input.teamCount && input.participantCount / input.teamCount != maxParticipantsPerTeam;

    if (step.isArbitraryAssign()) {
      options.push(
        <option key={`assignTeamsPerParticipant_${step.sequence}_${0}`} value={0}>{`Any number of teams may be assigned`}</option>
      );
      if (step.isAdminAssign()) {
        return options;
      }
    }

    if (step.methodId === Constants.methodType.Draft) {
      // we currently only support drafting 1 team per participant per step
      return [
        <option key={`assignTeamsPerParticipant_${step.sequence}_1`} value={1}>{`Each participant gets 1 team`}</option>
      ];
    }

    if ((input.teamCount > input.participantCount) || (step.isSelfAssign() && maxAssignsPerParticipant > 0)) {
      for (let i = 1; i <= (step.isSelfAssign() ? maxAssignsPerParticipant : maxTeamsPerParticipant); i++) {
        options.push(
          <option key={`assignTeamsPerParticipant_${step.sequence}_${i}`} value={i}>{`Each participant gets ${i} team${i === 1 ? '' : 's'}`}</option>
        );
      }
    } else if (input.participantCount > input.teamCount) {
      for (let i = 1; i <= maxParticipantsPerTeam; i++) {
        options.push(
          <option key={`assignParticipantsPerTeam_${step.sequence}_${i}`} value={i * -1}>{`Each team gets ${i} participant${i === 1 ? '' : 's'}`}</option>
        );
      }
    }

    if ((hasLeftoverTeams || hasLeftoverParticipants || !options.length) && !step.isArbitraryAssign()) {
      options.push(<option key={`assign_${step.sequence}_0`} value={0}>{`${isMirror ? "Everyone" : "Everything"} gets assigned`}</option>);
    }

    return options;
  } else {
    const count = (isMirror ? input.participantCount : (input.matchCount || input.teamCount)) - 1;
    return [...Array(count >= 0 ? count : 0).keys()].map(x => {
      x++;
      return (<option key={`electOrExclude_${step.sequence}_${x}`} value={x}>{`${step.isElect() ? 'Elect' : 'Exclude'} ${TextService.pluralize(x, isMirror ? "participant" : "team")}`}</option>);
    });
  }
}

export function MethodStep(props) {

  const step = props.step;
  const update = props.handleUpdate;
  const { resolutionTypeId, dateShouldResolve } = step;
  const availableResolutionCounts = getResolutionOptions(step, props.isMirror).map(x => x.props.value);
  const resolutionCount = availableResolutionCounts.includes(step.resolutionCount) ? step.resolutionCount : (availableResolutionCounts?.[0] ?? 1);

  const getPredictedOutput = (step, totalParticipantCount, resolutionCount, isMirror) => {
    const i = step.input;
    let o = getIo(0, 0, i.matchCount, step);
    if (!step.canPredict()) {
      o.teamCount = i.teamCount;
      o.participantCount = i.participantCount;
      return o;
    } else if (isMirror && i.participantCount) {
      if (step.isAssign()) {
        o.matchCount += i.participantCount;
      } else if (step.isElect()) {
        o.participantCount = resolutionCount;
      } else if (step.isExclude()) {
        o.participantCount = i.participantCount - resolutionCount;
      }
    } else if (i.teamCount || (step.isSelfAssign() && i.matchCount)) {
      if (step.isAssign()) {
        if (step.isSelfAssign()) {
          o.matchCount = (step.resolutionCount === 0) ? i.matchCount : ((step.resolutionCount * i.participantCount) + ((i.getAssignType() === Constants.assignType.ExistingMatches) ? i.matchCount : 0));
        } else if (resolutionCount === 0) {
          o.matchCount += Math.max(i.teamCount, totalParticipantCount)
        } else {
          o.matchCount += Math.abs(resolutionCount) * Math.min(i.teamCount, i.participantCount);
        }
      }
      if (step.isAssign()) {
        o.teamCount = Math.max(i.teamCount - o.matchCount + i.matchCount, 0);
      } else if (step.isElect()) {
        o.teamCount = Math.max(resolutionCount, 0);
      } else if (step.isExclude()) {
        o.teamCount = Math.max(i.teamCount - resolutionCount, 0);
      }
      o.participantCount = i.participantCount;
    }
    return o;
  }

  useEffect(() => {
    const o = getPredictedOutput(step, props.totalParticipantCount, resolutionCount, props.isMirror);
    const shouldUpdate = (o.matchCount !== step.output.matchCount) || (o.teamCount !== step.output.teamCount) || (o.participantCount !== step.output.participantCount) || (o.getAssignType() !== step.output.getAssignType());
    if (shouldUpdate) {
      step.output = o;
      update(step);
    }
  }, [step, props.totalParticipantCount, resolutionCount, props.isMirror, update, getPredictedOutput]);

  const handleMethodSelect = (e) => {
    const selectedMethod = props.methods.find(x => x.id === parseInt(e.target.value));
    if (selectedMethod) {
      const supportedAlgorithms = props.algorithms.filter(x => x.supportedResolutionTypeIds.includes(resolutionTypeId));
      const defaultAlgorithmId = supportedAlgorithms.find(x => selectedMethod.defaultAlgorithmIds.includes(x.id))?.id || 0;
      step.methodId = selectedMethod.id;
      step.algorithmId = defaultAlgorithmId;
      step.dateShouldResolve = (selectedMethod.isTimeLimitSupported) ? dateShouldResolve : null;
      if (selectedMethod.id === Constants.methodType.Admin || selectedMethod.id === Constants.methodType.SelfAssign) {
        step.resolutionCount = 0;
      }
    } else {
      step.methodId = 0;
      step.algorithmId = 0;
    }

    update(step);
  }

  const handleResolutionTypeSelect = (e) => {
    step.resolutionTypeId = parseInt(e.target.value) || Constants.resolutionType.None;
    step.resolutionCount = 1;
    const selectedMethod = getSelectedMethod();
    let newAlgorithmId = 0;
    if (selectedMethod) {
      const defaultAlgorithm = props.algorithms.find(x =>
        selectedMethod.supportedAlgorithmIds.includes(x.id) &&
        x.supportedResolutionTypeIds.includes(resolutionTypeId));
      newAlgorithmId = defaultAlgorithm ? defaultAlgorithm.id : 0;
    }

    step.algorithmId = newAlgorithmId;

    update(step);
  }

  const hasSelectedMethodTimeLimit = () => {
    const selectedMethod = getSelectedMethod();
    return selectedMethod?.supportedAlgorithmIds.length && selectedMethod?.isTimeLimitSupported;
  }

  const getTimeLimitControls = () => {
    if (!hasSelectedMethodTimeLimit()) return null;

    return (
      <div className="padding-topbottom-10">
        <FormLabel>
          {"Deadline"}
          <Icon icon="question" helptext="Once all participants have committed their preferences, our algorithm will calculate the outcomes. If any participants have not committed preferences before the deadline, the algorithm will run without them. Leave this blank if you don't want to use it." />
        </FormLabel>
        <DatePicker
          className="form-control"
          wrapperClassName="form-control"
          selected={dateShouldResolve}
          onChange={(actualDate) => { step.dateShouldResolve = actualDate; update(step)}}
          placeholderText="Timeframe for committing team picks"
          showTimeSelect
          timeIntervals={30}
          timeCaption="time"
          dateFormat={DateService.controlDateFormat}
        />
      </div>
    );
  }

  const getPredictionText = () => {
    if (!canPredict()) return null;

    const i = step.input;
    const o = step.output;
    const newMatchCount = o.matchCount - i.matchCount;
    const assignedTeamCount = Math.max(i.teamCount - o.teamCount, 0);
    const matchesPerParticipantFloor = props.totalParticipantCount ? Math.floor(newMatchCount / props.totalParticipantCount) : 0;
    const matchesPerParticipantCeiling = props.totalParticipantCount ? Math.ceil(newMatchCount / props.totalParticipantCount) : 0;
    const matchesPerParticipantText = (matchesPerParticipantFloor === matchesPerParticipantCeiling)
      ? matchesPerParticipantFloor
      : `${matchesPerParticipantFloor} or ${matchesPerParticipantCeiling}`;
    let explanationText = "";

    if (step.isAssign()) {
      explanationText = "This step will assign " + (props.isMirror
        ? (
          `${TextService.pluralize(props.totalParticipantCount, "participant")} to each other so that each participant is assigned ` +
          `${TextService.pluralize(matchesPerParticipantText, "other participant")}, leaving ` +
          `${TextService.pluralize(o.participantCount, "participant")} left over.`
        ) : (
          `${TextService.pluralize(props.totalParticipantCount, "participant")} with ` +
          `${TextService.pluralize(matchesPerParticipantText, "team")} each` +
          (o.TeamCount + o.participantCount === 0 ? '' : `, leaving ${TextService.pluralize(o.teamCount, "team")} and ` +
          `${TextService.pluralize(o.participantCount, "participant")} left over`) +
          '.'
        ));
    } else if (step.isElect()) {
      explanationText = `This step will elect ${step.resolutionCount} of the ` +
        (props.isMirror ? `${TextService.pluralize(props.totalParticipantCount, "participant")}` : `${TextService.pluralize(assignedTeamCount + o.teamCount, "team")}`) +
        `. No matches will be assigned in this step.`;
    } else if (step.isExclude()) {
      explanationText = "This step will exclude " +
        `${TextService.pluralize(resolutionCount, props.IsMirror ? "participant" : "team")}, so that ` +
        (props.isMirror ? `${TextService.pluralize(props.totalParticipantCount, "participant")}` : `${TextService.pluralize(o.teamCount, "team")}`) + ` remain${o.teamCount === 1 ? "s" : ""}. No matches will be assigned in this step.`;
    }
    return (
      <p>
        {explanationText}
      </p>);
  }

  const getPredictionControls = () => {
    if (!canPredict()) {
      const minTeamCount = step.isAssign() ? Constants.minAssignTeamCount : Constants.minElectOrExcludeTeamCount;
      const warningText = (step.sequence === 1)
        ? `please select ${step.input.teamCount ? "participants" : "teams"}`
        : `the preceding method must output at least ${TextService.pluralize(minTeamCount, "team")}`;
      return <p className="no-margin">{`To ${TextService.getResolutionVerb(resolutionTypeId, false)} teams, ${warningText}.`}</p>;
    }

    return (
      <PredictionDisplay
        i={step.input}
        o={step.output}
        isMirror={props.isMirror}
      />);
  }

  const canPredict = () => {

    if (step.input.participantCount === 0) return false;

    if (props.isMirror) return true;

    if (step.isAssign()) return step.isArbitraryAssign() || (step.input.teamCount >= Constants.minAssignTeamCount);

    return (step.input.matchCount || step.input.teamCount) >= Constants.minElectOrExcludeTeamCount;
  }

  const handleResolutionCountSelect = (e) => {
    step.resolutionCount = parseInt(e.target.value) || 0;
    if (step?.isAssign() === true) {
      if (step.isSelfAssign()) {
        step.assignType = Constants.assignType.ExistingMatches;
      } else if (step.canPredict()) {
        step.assignType = Constants.assignType.CannotPredict;
      } else if (step.resolutionCount > 0) {
        step.assignType = Constants.assignType.TeamsPerParticipant;
      } else if (step.resolutionCount < 0) {
        step.assignType = Constants.assignType.ParticipantsPerTeam;
      } else {
        step.assignType = Constants.assignType.AllAvailableTeams;
      }
    } else {
      step.assignType = Constants.assignType.NotAssign;
    }

    update(step);
  };

  const getPurposeControls = () => {
    const input = step.input;
    const isDisabled = !canPredict();
    let options = [];

    let assignText = "Assign teams to participants";
    let electText = "Elect a subset of teams";
    let excludeText = "Exclude a subset of teams";
    if (props.isMirror) {
      assignText = "Assign participants to each other";
      electText = "Elect a subset of participants";
      excludeText = "Exclude a subset of participants";
    }

    if (!isDisabled) {
      options = getResolutionOptions(step, props.isMirror);
    } else {
      var errorText = 'Select participants first';

      if (input.teamCount <= 0) {
        if (props.isMirror) {
          if (step.sequence > 1) {
            errorText = 'Not enough participants left';
          }
        } else if (step.sequence === 1) {
          errorText = 'Select a theme first';
        } else {
          errorText = 'Not enough teams left';
        }
      }

      options = [<option key={'invalid'}>{errorText}</option >];
    }

    return (
      <div className="padding-bottom-10 wide-mobile">
        <FormLabel>
          {"Purpose"}
          <Icon icon="question" helptext="Methods will assign, elect, or exclude teams from the stir. Results from a method feed into each other, so one method's output is the next method's input." />
        </FormLabel>
        <div className="double-control-container">
          <Form.Select value={resolutionTypeId} onChange={handleResolutionTypeSelect} onLoad={handleResolutionTypeSelect} >
            <option key={`resolution_${step.sequence}_${Constants.resolutionType.Assign}`} value={Constants.resolutionType.Assign}>{assignText}</option>
            <option key={`resolution_${step.sequence}_${Constants.resolutionType.Elect}`} value={Constants.resolutionType.Elect}>{electText}</option>
            <option key={`resolution_${step.sequence}_${Constants.resolutionType.Exclude}`} value={Constants.resolutionType.Exclude}>{excludeText}</option>
          </Form.Select>
          <span className="stretch"></span>
          <Form.Select value={resolutionCount}
            onChange={handleResolutionCountSelect}
            disabled={isDisabled}>
            {options}
          </Form.Select>
        </div>
      </div>);
  }

  const getSelectedMethod = () => {
    return props.methods.find(x => x.id === step.methodId);
  }

  const getSelectedAlgorithm = () => {
    return props.algorithms.find(x => x.id === step.algorithmId);
  }

  const getTopControls = () => {
    return (props.isOnlyStep || !props.isExpanded) ? null : (
      <div className="icon-bar">
        {<Icon css="stir-icon-toggle interactable" icon="trash" onClick={(e) => props.handleMethodStepAction(step.sequence, action.delete)} helptext="remove this step" />}
        {<Icon css="stir-icon-toggle interactable" icon="shrink" onClick={(e) => props.handleMethodStepAction(step.sequence, action.collapse)} helptext="collapse step details" />}
      </div>
    );
  }

  const getMethodControls = () => {
    if (!props.algorithms.length || !props.methods.length) return null;
    const selectedMethod = getSelectedMethod();

    const pluralNoun = props.isMirror ? "them" : "teams"
    const verbPhrase =
      step.isAssign() ? (props.isMirror ? "assign" : "assign teams to") :
        step.isElect() ? (props.isMirror ? "elect" : "elect teams for") :
          step.isExclude() ? (props.isMirror ? "exclude" : "exclude teams from") :
            "";

    const explanation = `Select a method to ${verbPhrase} your participants. Each method has one or more algorithms that allocate ${pluralNoun} in different ways.`;

    const availableMethods = props.methods.filter(method => method.supportedResolutionTypeIds.includes(resolutionTypeId));

    return (
      <>
        <div className="padding-topbottom-10">
          <FormLabel>
            {"Method"}
            <Icon icon="question" helptext="A teamstir method is the overall strategy for assigning teams. Each method has at least one algorithm from which to choose." />
          </FormLabel>
          <Form.Select value={step.methodId} onChange={handleMethodSelect} onLoad={handleMethodSelect}>
            <option key={`method_${step.sequence}_${0}`} value={0}></option>
            {availableMethods.map(x => <option key={`method_${step.sequence}_${x.id}`} value={x.id}>{x.name}</option>)}
          </Form.Select>
        </div>
        <p>
          {selectedMethod ? selectedMethod.explanation : explanation}
        </p>
      </>);
  }

  const getAlgorithmControls = () => {
    if (!props.algorithms.length || !props.methods.length) return null;

    const selectedAlgorithm = getSelectedAlgorithm();
    const selectedMethod = getSelectedMethod();
    let availableAlgorithms = [];
    if (selectedMethod) {
      availableAlgorithms = props.algorithms
        .filter(x =>
          selectedMethod.supportedAlgorithmIds.includes(x.id) &&
          x.supportedResolutionTypeIds.includes(resolutionTypeId));
    }
    return (availableAlgorithms.length > 1) && (
      <>
        <div className="padding-topbottom-10">
          <FormLabel>
            {"Algorithm"}
            <Icon icon="question" helptext="A teamstir algorithm is a formula for assigning teams using personal settings and objective rules. Read the help text for each to learn more." />
          </FormLabel>
          <Form.Select disabled={!availableAlgorithms.length} value={step.algorithmId} //algorithm selection
            onChange={(e) => {
              step.algorithmId = parseInt(e.target.value) || 0;
              update(step);
            }}>
            {availableAlgorithms.map(x => <option key={`algorithm_${step.sequence}_${x.id}`} value={x.id}>{x.name}</option>)}
          </Form.Select>
        </div>
        {selectedAlgorithm?.explanation && (
          <p>
            {selectedAlgorithm.explanation}
            {selectedAlgorithm.explanationUrl && (
              <>
                &nbsp;&nbsp;
                <a className="action-text" href={selectedAlgorithm.explanationUrl} target="_blank" rel="noopener noreferrer">Read more on wikipedia.</a>
              </>
            )}
          </p>
        )}
      </>);
  }

  const getSummaryControls = () => {
    if (!canPredict()) return null;

    const methodName = getSelectedMethod()?.name;
    const algorithmName = getSelectedAlgorithm()?.name;
    const resolutionTime = DateService.getDurationText(dateShouldResolve);

    let controls = [];
    if (methodName) controls.push(<span>{`${methodName.toLowerCase()} ${TextService.getResolutionNoun(resolutionTypeId)}`}</span>);
    if (algorithmName) controls.push(<span>{`${algorithmName.toLowerCase()} algorithm`}</span>);
    if (resolutionTime) controls.push(<span>{`resolves ${resolutionTime}`}</span>);
    else if (hasSelectedMethodTimeLimit()) controls.push(<span>{`no time limit`}</span>);

    if (!controls.length) {
      const resolutionVerb = TextService.getResolutionVerb(resolutionTypeId, false);
      controls.push(<span>{`Select a method to ${resolutionVerb} your ${props.isMirror ? "participants" : "teams"}.`}</span>);
    }

    return (
      <div className="method-step-summary-text">
        {controls.reduce((a, b) => a === null ? b : <>{a}<Icon icon="stir" css="method-step-text-separator" />{b}</>, null)}
      </div>
    );
  }

  return (
    <div className={`method-step-container${props.isExpanded ? "" : " collapsed"}`} onClick={props.isExpanded ? null : (e) => props.handleMethodStepAction(props.step.sequence, action.expand)}>
      {getTopControls()}
      {props.isExpanded ?
        <>
          {getPurposeControls()}
          {getPredictionText()}
          {getPredictionControls()}
          {getMethodControls()}
          {getAlgorithmControls()}
          {getTimeLimitControls()}
        </>
        :
        <>
          {getPredictionControls()}
          {getSummaryControls()}
        </>
      }
    </div>
  );
}
