import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Alert } from 'react-bootstrap';
import { StirTile } from '../tiles/StirTile';
import { MatchTile } from '../tiles/MatchTile';
import { ParticipantTile } from '../tiles/ParticipantTile';
import { Spinner } from '../shared/Spinner';
import { NoContent } from '../shared/NoContent';
import { Breadcrumbs } from '../shared/Breadcrumbs';
import { Icon } from '../shared/Icon';
import { UrlService } from '../../services/UrlService';
import EntityService from '../../services/EntityService';
import WebsocketService from '../../services/WebsocketService';
import Validator from '../../services/ValidationService';
import Constants from '../../services/Constants';
import Stir from '../../entities/Stir';
import Match from '../../entities/Match';

export function StirDetails(props) {
  const [stir, setStir] = useState(null);
  const isLoadingStir = useRef();
  const isLoadingEvent = useRef();
  const isLoadingDraftPicks = useRef();
  const [draftTeamPicks, setDraftTeamPicks] = useState(undefined);
  const [notFound, setNotFound] = useState(false);
  const [isNewlyResolved, setIsNewlyResolved] = useState(UrlService.getUrlParam("status") === "resolved");

  UrlService.useTitle('Stir Details');

  const loadEvent = (data) => {
    if (data && Validator.isHashValid(data.hash, Constants.hash.event)) {
      stir.event = data;
      setStir(stir);
    }
  }

  const loadStir = (data) => {
    if (!data) {
      isLoadingStir.current = false;
      setNotFound(true);
    } else {
      const loadedStir = new Stir(data);

      setStir(loadedStir);
      setIsNewlyResolved(isNewlyResolved || (loadedStir.currentStep.dateResolved && stir?.hash && !stir?.currentStep.dateResolved));

      const from = UrlService.getUrlParam("from");
      const cleanUrl = UrlService.getUrl(`/${loadedStir.hash}`, from ? `from=${from}` : "");
      if (cleanUrl !== UrlService.getCurrentUrl()) {
        props.navigate(cleanUrl);
      }
    }
  }

  const loadDraftPicks = useCallback((stir) => {
    if (Validator.isHashValid(stir.hash) && stir.currentStep.isDraft() && draftTeamPicks === undefined) {
      EntityService.getAsyncData((data) => {
        if (data) {
          setDraftTeamPicks(data);
        }
      }, `api/pickLists/draft/${props.hash}?user=${props.user.hash}`, isLoadingDraftPicks);
    }
  }, []);

  const updateStir = (hash) => {
    if (hash === UrlService.getHashFromUrl() && !isLoadingStir.current && !notFound) {
      EntityService.getAsyncData(loadStir, `api/${hash}?user=${UrlService.getUserHashFromUrl()}`, isLoadingStir);
    }
  }

  useEffect(() => {
    updateStir(props.hash);

    var socket = WebsocketService.openWebsocket((event) => {
      if (event.data.includes(props.hash)) {
        updateStir(props.hash);
        if (event.data.includes('Resolved')) {
          socket.close(1000, 'stir resolved');
        }
      }
    });
  }, []);

  useEffect(() => {
    if (!stir) return;
    loadDraftPicks(stir);
    if (Validator.isHashValid(stir.eventHash)) {
      EntityService.getEntityAsync(loadEvent, stir.eventHash, isLoadingEvent);
    }
  }, [stir]);

  const getMatches = (stir) => {
    // Secret reveals only show current user's assignments (or none if user not in stir).
    let matches = [];
    if (stir.currentStep.isDraft()) {
      const draftedTeams = Object.values(draftTeamPicks ?? [])
        .filter(pick => pick.stepParticipant.isCommitted)
        .map(picks => picks.picks.map(p => {
          return new Match({
            assignedCount: 1,
            teamHash: p.teamHash,
            participantHash: picks.stepParticipant.participantHash,
            team: stir.theme.teams.find(t => t.hash === p.teamHash),
            participant: stir.participants.find(p => p.hash === picks.stepParticipant.participantHash)
          });
        })).flat();
      matches = stir.matches.concat(draftedTeams);
    } else if (!stir.isSecretReveal) {
      matches = stir.matches;
    } else if (stir.participants?.some(x => x.hash === props.user?.publicHash)) {
      matches = stir.matches.filter(x => x.participantHash === props.user.publicHash);
    }
    return matches
      .sort((a, b) => {
        // Sort results: assigned matches or elected/excluded teams first, then unassigned participants, then unassigned teams (break ties w/ stir points).
        const aType = a.participant?.stirParticipantTypeId ? 1 : -1;
        const bType = b.participant?.stirParticipantTypeId ? 1 : -1;
        const participantSort = bType - aType;
        if (participantSort !== 0) return participantSort;
        const aCount = (a.electedCount + a.excludedCount + a.assignedCount) || -1;
        const bCount = (b.electedCount + b.excludedCount + b.assignedCount) || -1;
        const teamSort = bCount - aCount;
        return (teamSort !== 0) ? teamSort : (b.teamScore - a.teamScore);
      });
  }

  const getContent = () => {
    if (!stir) return null;

    const matches = getMatches(stir).map(x =>
      <MatchTile key={x.teamHash + x.participantHash} match={x} showStirPoints={stir.totalStirPoints > 0} isSecretReveal={stir.isSecretReveal} isDraft={stir.currentStep.isDraft()} />
    );

    const matchedParticipantHashes = matches.filter(m => m.props.match.participant).map(m => m.props.match.participant.hash);

    // If stir is complete with assignments, display them with unassigned participants and teams in full width.
    if (stir.dateResolved && stir.matches?.length) {
      const unassignedParticipants = stir.participants.filter(p => !matchedParticipantHashes.includes(p.hash)).map(p =>
        <ParticipantTile
          key={p.hash}
          participant={p}
          hideStats={true}
          isSecretReveal={stir.isSecretReveal}
        />
      );
      return matches.concat(unassignedParticipants);
    }
    // Otherwise, either the stir is active or there are no assignments.
    // In either case, show participants (w / observers) and teams (and possibly matches from a previous step).
    const maxPriority = Math.max(...stir.stepParticipants.map(p => p.priority));
    const activePriority = stir.stepParticipants.find(p => p.participantHash === stir.currentStep.activeUserHash)?.priority;
    const participants = stir.participants
      .sort((a, b) => {
        if (a.isObserver !== b.isObserver) return a.isObserver - b.isObserver;
        if (stir.currentStep.isDraft() && stir.stepParticipants.length) {
          let aPriority = stir.stepParticipants.find(p => p.participantHash === a.hash)?.priority;
          let bPriority = stir.stepParticipants.find(p => p.participantHash === b.hash)?.priority;
          aPriority = (aPriority >= activePriority) ? aPriority : (aPriority + maxPriority);
          bPriority = (bPriority >= activePriority) ? bPriority : (bPriority + maxPriority);
          return aPriority - bPriority;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        return 0;
      });
    if (participants?.length || matches?.length) {
      return (
        <div className="tile-dual-outer">
          <div className="tile-dual-inner">
            {participants.map((p, i) =>
              <ParticipantTile
                key={p.hash}
                participant={p}
                isSecretReveal={stir.isSecretReveal}
                activeUserOrder={stir.currentStep.isDraft() ? i : undefined}
              />)}
          </div>
          <span className="stretch"></span>
          <div className="tile-dual-inner">
            {matches}
          </div>
        </div>);
    }
    return <NoContent noun={"matches"} />
  }

  const getStatusAlert = () => {
    if (isNewlyResolved) {
      let totalStepCount = 0;
      let resolvedStepCount = 0;

      if (stir.steps) {
        totalStepCount = stir.steps.length;

        stir.steps
          .some((step) => {
            if ((stir.currentStepHash === step.hash) && !stir.currentStep.dateResolved) {
              return true;
            }
            resolvedStepCount++;
            return false;
          });
      }
      const stepText = totalStepCount > 1 ? ` step ${resolvedStepCount} of ${totalStepCount}` : "";
      return (<Alert transition={null} className="left" variant="success"><Icon icon="check" css="status-alert-icon" />{`Your stir just resolved${stepText}. Here are the results.`}</Alert >);
    }

    return null;
  }

  if (notFound) return <NoContent noun={"stir"} />;

  if (isLoadingStir.current || !stir) return <Spinner />;

  return (
    <div className="center">
      <div className="tile-container">
        <Breadcrumbs user={props.user} stir={stir} theme={stir.theme} event={stir.event} />
        <StirTile
          key={stir.hash}
          user={props.user}
          stir={stir}
          isHeader={true}
          page={Constants.page.Stir}
        />
        {getStatusAlert()}
        {getContent()}
      </div>
    </div>);
}
