import Constants from "../../services/Constants";

//this class is used only to submit new stirs. During creation, a different version is used
export default class StirForm {

  constructor(stir) {
    this.customMessage = stir.customMessage;
    this.participants = this.getParticipantsFromStir(stir);
    this.theme = this.getThemeFromStir(stir);
    this.methodSteps = this.getMethodStepsFromStir(stir);
    this.event = this.getEventFromStir(stir);
    this.tieBreakerTypeId = stir.selectedTieBreakerTypeId;
    this.shouldEmailInvitations = stir.shouldEmailInvitations;
    this.timeZone = stir.timeZone;
  }

  getParticipantsFromStir(stir) {
    return stir.participants
      .filter(p => p && ((p.name && p.name.length) || (p.email && p.email.length)))
      .map(p => ({ name: p.name, email: p.email, stirParticipantTypeId: p.stirParticipantTypeId, priority: p.priority }));
  }

  getEventFromStir(stir) {
    return {
      administratorEmail: stir.participants.find(p => p.stirParticipantTypeId === Constants.stirParticipantType.AdminParticipant || p.stirParticipantTypeId === Constants.stirParticipantType.AdminObserver)?.email,
      hash: stir.eventHash,
      name: stir.eventName,
      message: stir.eventMessage,
      eventDate: stir.eventDate,
      timeZone: stir.timeZone,
      address: stir.eventAddress
    };
  }

  getMethodStepsFromStir(stir) {
    return stir.methodSteps.map(x => {
      return {
        methodId: x.methodId,
        algorithmId: x.algorithmId,
        shouldEmailParticipants: x.shouldEmailParticipants,
        dateShouldResolve: x.dateShouldResolve,
        resolutionCount: x.resolutionCount,
        resolutionTypeId: x.resolutionTypeId,
        resolutionSequence: x.sequence,
        revealTypeId: stir.revealTypeId,
      }
    });
  }

  getThemeFromStir(stir) {
    const theme = stir.getTheme() || {};
    return {
      name: stir.customThemeName || theme.name,
      hash: theme.hash,
      themeTypeId: stir.isEditingTheme ? Constants.themeType.Custom : (stir.selectedThemeTypeId || theme.themeTypeId),
      minParticipants: stir.getMinParticipantCount(),
      maxParticipants: stir.getMaxParticipantCount(),
      bggId: theme.bggId,
      teams: this.getTeamsFromStir(stir, theme),
    };
  }

  getTeamsFromStir(stir, theme) {
    if (stir.selectedThemeTypeId === Constants.themeType.Game && !stir.isEditingTheme) {
      return theme.teams;
    } else if (stir.selectedThemeTypeId === Constants.themeType.MirrorParticipants && !stir.isEditingTheme) {
      return stir.participants
        .map(p => { return { name: p.name, hash: null, winCount: null, averagePreference: null, links: [] } })
        .filter(p => p.name?.length);
    }
    return stir.customTeamText.split('\n').map(t => (
      { name: t.trim(), hash: null, winCount: null, averagePreference: null, links: [] }
    )).filter(t => t.name.length);
  }
}
